import store from '@/store';
import {Notification} from 'element-ui';

var COS = require('cos-js-sdk-v5');
var cos = new COS({
    SecretId: 'AKIDw56vXyyH8mHdsv6W7ViZBQWNzObhItkT',
    SecretKey: 'mJTTFnU0H7nFUUI0Uv5rTsLF3e5UlrCC'
});

const config = {
    Bucket: 'copyright-pro-temp-1259800220',
    Region: 'ap-beijing'
};

const upload = {
    // 分片上传
    /*option:{
        data:event,
        onProgress:function(),
        onError:function(),
        onSuccess:function()
    }*/
    sliceUploadFile: function(option) {
        const _data = option.data;
        // this.getBucketCors();
        if (_data) {
            const _fileName = _data.name;
            const _key = option.taskKey + '-' + _fileName;
            const _file = _data;
            cos.sliceUploadFile(
                {
                    Bucket: config.Bucket,
                    Region: config.Region,
                    Key: _key,
                    Body: _file,
                    onTaskReady: taskId => {
                        Notification({
                            title: '提示',
                            message: '文件上传过程中不影响其他操作'
                        });
                        const _data = {
                            taskId: taskId,
                            fileName: _fileName,
                            key: _key
                        };
                        option.onTaskReady ? option.onTaskReady(_data) : '';
                    },
                    onProgress: progressData => {
                        this.getTaskList();
                        option.onProgress ? option.onProgress(progressData) : '';
                    }
                },
                (err, data) => {
                    if (err) {
                        Notification({
                            title: '提示',
                            message: _fileName + '上传失败',
                            type: 'error'
                        });
                        option.onError ? option.onError(err) : '';
                    }
                    if (data) {
                        Notification({
                            title: '提示',
                            message: _fileName + '上传成功',
                            type: 'success'
                        });
                        const _url = this.getObjectUrl(_key);
                        option.onSuccess ? option.onSuccess({...data, url: _url, fileName: _fileName}) : '';
                    }
                    this.getTaskList();
                }
            );
        }
    },
    //获取带签名的对象的 Url
    getObjectUrl: function(key) {
        return cos.getObjectUrl({
            Bucket: config.Bucket,
            Region: config.Region,
            Key: key
        });
    },
    //列出目录x的所有文件
    getBucket: function() {
        cos.getBucket(
            {
                Bucket: config.Bucket,
                Region: config.Region
                // Prefix: 'a/',           /* 非必须 */
            }
            // function(err, data) {
            // }
        );
    },
    //删除文件
    deleteObject: function(key) {
        cos.deleteObject(
            {
                Bucket: config.Bucket,
                Region: config.Region,
                Key: key
            }
            // function(err, data) {
            // }
        );
    },
    //查看上传队列
    getTaskList: function() {
        const _allTaskList = cos.getTaskList();
        const _uploading = [];
        const _taskList = [];
        _allTaskList.map(item => {
            if (item.state == 'uploading') {
                _uploading.push(item);
            }
            if (item.state != 'canceled') {
                _taskList.push(item);
            }
        });
        store.dispatch('setTaskList', {
            taskList: _taskList,
            uploading: _uploading,
            btnVisible: true
        });
    },
    //查询跨域配置
    getBucketCors: function() {
        cos.getBucketCors(
            {
                Bucket: config.Bucket,
                Region: config.Region
            },
            function(err, data) {
                console.log(data);
            }
        );
    },
    //设置跨域配置
    putBucketCors: function() {
        cos.putBucketCors(
            {
                Bucket: config.Bucket,
                Region: config.Region,
                CORSRules: [
                    {
                        AllowedOrigin: [
                            'http://localhost:8881',
                            'http://localhost:8882',
                            'http://localhost:8883',
                            'http://localhost:8282',
                            'http://localhost:8383',
                            '*.dmhmusic.com',
                            '*.th-music.com',
                            '*.indieworks.cn',
                            '*.submusic.cn',
                            '*.showstart.com',
                        ],
                        AllowedMethod: ['GET', 'POST', 'PUT', 'DELETE', 'HEAD'],
                        AllowedHeader: ['*'],
                        ExposeHeader: ['ETag', 'x-cos-acl', 'x-cos-version-id', 'x-cos-delete-marker', 'x-cos-server-side-encryption']
                        // MaxAgeSeconds: '5'
                    }
                ]
            }
            // function(err, data) {
            // }
        );
    },
    //查询对象元数据
    headObject: function(key) {
        cos.headObject(
            {
                Bucket: config.Bucket,
                Region: config.Region,
                Key: key /* 必须 */
            }
            // function(err, data) {
            // }
        );
    },
    //暂停上传任务
    pauseTask: function(id) {
        cos.pauseTask(id);
        this.getTaskList();
    },
    //重启上传任务
    restartTask: function(id) {
        cos.restartTask(id);
        this.getTaskList();
    },
    //取消上传任务
    cancelTask: function(id) {
        cos.cancelTask(id);
        this.getTaskList();
    }
};

export default upload;
